
.widgets__calendar {

  .datepicker-inline {
    width: 100% !important;
  }

  .datepicker table thead tr:last-child {
    color: #bdbdbd;
  }

  .datepicker table tbody td {
    font-weight: 900;
    color: #616161 !important;
  }

  .datepicker table tbody td.old,
  .datepicker table tbody td.new {
    color: #bdbdbd !important;
  }

  .datepicker table tbody td.today,
  .datepicker table tbody td.active {
    color: #ffffff !important;
  }

  #calendar .datepicker table tbody tr td.active {
    background-color: #f44336;
    border-radius: 30px;
  }

  @media only screen and (min-width: 992px) {

    .datepicker.datepicker-inline td,
    .datepicker.datepicker-inline th {
      padding: 5px 3px;
      font-size: 0.8rem;
    }

    .datepicker table tr td,
    .datepicker table tr th {
      height: 25px;
    }

  }

  @media only screen and (min-width: 1200px) {

    .datepicker.datepicker-inline td,
    .datepicker.datepicker-inline th {
      padding: 7px 5px;
      font-size: 1rem;
    }

    .datepicker table tr td,
    .datepicker table tr th {
      height: 30px;
    }

  }

}
